import axios from 'axios';
import jwtHelper from './utils/jwtHelper.js';

const ddResponseInterceptor = (res) => {
    if (res.headers && res.headers.postop === 'Renew') {
        return jwtHelper.refresh().then(() => {
            return res;
        });
    } else {
        return res;
    }
};

const ddResponseInterceptorError = (error) => {
    const originalRequest = error.config;
    const status = error.response ? error.response.status : null;
    if (status === 401 && !originalRequest._retry) {
        if (typeof error.response.data.error !== 'undefined' && error.response.data.error.code === 108) {
            originalRequest._retry = true;
            return jwtHelper.refresh().then(() => {
                originalRequest.headers['GigyaJWT'] = jwtHelper.get();
                return axios(originalRequest);
            });
        }
    }
    return Promise.reject(error);
};

const ddRequestInterceptor = (config) => {
    if (config.headers) {
        config.headers['GigyaJWT'] = jwtHelper.get();
    }
    return config;
};

export const Articles = axios.create({
    baseURL: `${process.env.REACT_APP_S3_BASE_URL}/json-exports`,
});

export const UserArticles = axios.create({
    baseURL: `${process.env.REACT_APP_DIRECTUS_BASE_URL}/items/users_articles`,
});

export const UserActions = axios.create({
    baseURL: `${process.env.REACT_APP_DIRECTUS_BASE_URL}/items/users_actions`,
});

export const Horoscope = axios.create({
    baseURL: `${process.env.REACT_APP_DIRECTUS_BASE_URL}/items`,
});

export const Zodiac = axios.create({
    baseURL: `${process.env.REACT_APP_DIRECTUS_BASE_URL}/items/zodiacal_signs`,
});

export const CustomToken = axios.create({
    baseURL: `${process.env.REACT_APP_DIRECTUS_BASE_URL}/custom/tokens`,
});

export const Events = axios.create({
    baseURL: `${process.env.REACT_APP_DIRECTUS_BASE_URL}`,
});

export const BlockCards = axios.create({
    baseURL: `${process.env.REACT_APP_DIRECTUS_BASE_URL}/items/block_cards`,
});

export const Tickets = axios.create({
  //  baseURL: `${process.env.REACT_APP_DIRECTUS_BASE_URL}/items/tickets`,
    baseURL: `${process.env.REACT_APP_DIRECTUS_BASE_URL}/custom/receipt`,
});

export const TicketDetail = axios.create({
    baseURL: `${process.env.REACT_APP_S3_BASE_URL}/pwa/tickets`,
});

export const Files = axios.create({
    baseURL: `${process.env.REACT_APP_DIRECTUS_BASE_URL}/files`,
});

export const Prizes = axios.create({
    baseURL: `${process.env.REACT_APP_DIRECTUS_BASE_URL}/items/loyalty_prizes`,
});

export const Prize = axios.create({
    baseURL: `${process.env.REACT_APP_DIRECTUS_BASE_URL}/items/loyalty_prize_interactions`,
});

export const Codes = axios.create({
    baseURL: `${process.env.REACT_APP_DIRECTUS_BASE_URL}/custom/codes`,
});

export const UserCustom = axios.create({
    baseURL: `${process.env.REACT_APP_DIRECTUS_BASE_URL}/custom/user`,
});

export const Loyalty = axios.create({
    baseURL: `${process.env.REACT_APP_DIRECTUS_BASE_URL}/custom/loyalty`,
});

export const LoyaltyActive = axios.create({
    baseURL: `${process.env.REACT_APP_DIRECTUS_BASE_URL}/custom/loyalty`,
});

export const Share = axios.create({
    baseURL: `${process.env.REACT_APP_DIRECTUS_BASE_URL}/custom/articles/share`,
});

export const ChallengesConfig = axios.create({
    baseURL: `/fixtures/challenges_config.json`,
});

export const Coupons = axios.create({
    baseURL: `${process.env.REACT_APP_DIRECTUS_BASE_URL}/items/coupons`,
});

export const CouponActions = axios.create({
    baseURL: `${process.env.REACT_APP_DIRECTUS_BASE_URL}/custom/coupons`,
});

export const Notifications = axios.create({
    baseURL: `${process.env.REACT_APP_DIRECTUS_BASE_URL}/items/notifications?sort=-created_on`,
});

export const NotificationsHide = axios.create({
    baseURL: `${process.env.REACT_APP_DIRECTUS_BASE_URL}/items/notifications_hide`,
});

export const Firebase = axios.create({
    baseURL: `${process.env.REACT_APP_DIRECTUS_BASE_URL}/custom/user/tokens`,
});

export const Surveys = axios.create({
    baseURL: `${process.env.REACT_APP_DIRECTUS_BASE_URL}/items/survey`,
});

export const SurveysAnswers = axios.create({
    baseURL: `${process.env.REACT_APP_DIRECTUS_BASE_URL}/items/survey_data`,
});

export const StaticContent = axios.create({
    baseURL: `${process.env.REACT_APP_STATIC_CONTENT}`,
});

export const Interactions = axios.create({
    baseURL: `${process.env.REACT_APP_DIRECTUS_BASE_URL}/custom/interactions`,
});

Horoscope.interceptors.request.use(ddRequestInterceptor);
UserArticles.interceptors.request.use(ddRequestInterceptor);
CustomToken.interceptors.request.use(ddRequestInterceptor);
Tickets.interceptors.request.use(ddRequestInterceptor);
TicketDetail.interceptors.request.use(ddRequestInterceptor);
Files.interceptors.request.use(ddRequestInterceptor);
UserActions.interceptors.request.use(ddRequestInterceptor);
Prizes.interceptors.request.use(ddRequestInterceptor);
Prize.interceptors.request.use(ddRequestInterceptor);
Codes.interceptors.request.use(ddRequestInterceptor);
UserCustom.interceptors.request.use(ddRequestInterceptor);
Loyalty.interceptors.request.use(ddRequestInterceptor);
Share.interceptors.request.use(ddRequestInterceptor);
Coupons.interceptors.request.use(ddRequestInterceptor);
Codes.interceptors.response.use(ddRequestInterceptor);
CouponActions.interceptors.request.use(ddRequestInterceptor);
Notifications.interceptors.request.use(ddRequestInterceptor);
NotificationsHide.interceptors.request.use(ddRequestInterceptor);
Firebase.interceptors.request.use(ddRequestInterceptor);
Interactions.interceptors.request.use(ddRequestInterceptor);

Horoscope.interceptors.response.use(ddResponseInterceptor, ddResponseInterceptorError);
UserActions.interceptors.response.use(ddResponseInterceptor, ddResponseInterceptorError);
Events.interceptors.response.use(ddResponseInterceptor, ddResponseInterceptorError);
CustomToken.interceptors.response.use(ddResponseInterceptor, ddResponseInterceptorError);
//Tickets.interceptors.response.use(ddResponseInterceptor, ddResponseInterceptorError);
Files.interceptors.response.use(ddResponseInterceptor, ddResponseInterceptorError);
Prizes.interceptors.response.use(ddResponseInterceptor, ddResponseInterceptorError);
Prize.interceptors.response.use(ddResponseInterceptor, ddResponseInterceptorError);
UserCustom.interceptors.response.use(ddResponseInterceptor, ddResponseInterceptorError);
Loyalty.interceptors.response.use(ddResponseInterceptor, ddResponseInterceptorError);
Share.interceptors.response.use(ddResponseInterceptor, ddResponseInterceptorError);
Coupons.interceptors.response.use(ddResponseInterceptor, ddResponseInterceptorError);
Codes.interceptors.response.use(ddResponseInterceptor, ddResponseInterceptorError);
CouponActions.interceptors.response.use(ddResponseInterceptor, ddResponseInterceptorError);
Notifications.interceptors.response.use(ddResponseInterceptor, ddResponseInterceptorError);
NotificationsHide.interceptors.response.use(ddResponseInterceptor, ddResponseInterceptorError);
Firebase.interceptors.response.use(ddResponseInterceptor, ddResponseInterceptorError);
Interactions.interceptors.response.use(ddResponseInterceptor, ddResponseInterceptorError);

Horoscope.interceptors.response.use((res) => res, ddResponseInterceptor);
UserActions.interceptors.response.use((res) => res, ddResponseInterceptor);
Events.interceptors.response.use((res) => res, ddResponseInterceptor);
CustomToken.interceptors.response.use((res) => res, ddResponseInterceptor);
TicketDetail.interceptors.response.use((res) => res, ddResponseInterceptor);
Files.interceptors.response.use((res) => res, ddResponseInterceptor);

Tickets.interceptors.response.use(
    (response) => {return response},
    (error) => {return Promise.reject(error)}
)