import React, { useState, useContext } from 'react';
import AppContext from '../../contexts/appContext';
import { isLoyaltyActive, fixDateForAllBrowsers } from '../../utils';
import { CouponActions as CouponActionsAPI } from '../../Api.js';
import Cta from '../../components/Cta';
import PrizeCard from '../../components/PrizeCard';
import LazyImg from '../../components/LazyImg';
import HeadingPage from '../../components/HeadingPage';
import { Check } from '../../components/Icons';
import LoyaltyCard from '../../components/LoyaltyCard';

import style from './style.module.scss';

const Coupons = ({ items, onCouponGenerated }) => {
    const [loading, setLoading] = useState(false);
    const { state, dispatch } = useContext(AppContext);

    const couponTrigger = (id, status) => {
        if (loading) {
            return;
        }
        setLoading(true);

        if (status === 'created') {
            generateCoupon(id);
        } else if (status === 'generated') {
            dispatch({
                type: 'SET_ALERT_MODAL',
                payload: {
                    text: `<p><strong>Sei sicuro di voler scaricare il coupon?</strong></p><p>Assicurati di <strong>poter stampare a colori</strong> e di avere carta sufficiente nella stampante prima di procedere.</p>`,
                    primaryCta: {
                        label: 'stampa coupon',
                        onClick: () => downloadCoupon(id),
                    },
                    secondaryCta: {
                        onClick: () => closeCoupon(),
                    },
                },
            });
        } else {
            dispatch({
                type: 'SET_ALERT_MODAL',
                payload: {
                    text: `<p><strong>Si è verificato un problema</strong></p><p>Non è possibile generare o scaricare questo coupon.</p>`,
                    modifiers: ['error'],
                    secondaryCta: {
                        onClick: () => closeCoupon(),
                    },
                },
            });
        }
    };

    const generateCoupon = (id) => {
        dispatch({ type: 'SET_LOADING' });

        CouponActionsAPI.post('/generate', {
            coupon_id: id,
        })
            .then(({ data }) => {
                console.log("quaaa");
                console.log(data);
                setLoading(false);
                onCouponGenerated();
                dispatch({ type: 'UNSET_LOADING' });
            })
            .catch((error) => {
                setLoading(false);
                dispatch({ type: 'UNSET_LOADING' });
            });
    };

    const downloadCoupon = (id) => {
        CouponActionsAPI.post('/download', {
            coupon_id: id,
        })
            .then(({ data }) => {
                window.location.href = data.data.download_uri;
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const closeCoupon = () => {
        setLoading(false);
        dispatch({ type: 'UNSET_ALERT_MODAL' });
    };

    return (
        <div className={style.page}>
            <div className="content">
                <HeadingPage
                    text="DonnaD mette a tua disposizione dei buoni sconto (coupon) per acquistare i tuoi prodotti preferiti o provarne di nuovi. Il nuovo programma fedeltà 2024 ti consentirà di trasformare le tue azioni in punti D per ottenere buoni sconto e altri fantastici premi!"
                />
                <div className={style.info}>
                    <LazyImg src="/imgs/membership/slides/1.svg" alt="women" />
                    <div className={style.checklist}>
                        <ul>
                            <li>
                                <Check />
                                <span>
                                    <strong>Scegli</strong> il buono sconto che vorresti usare.
                                </span>
                            </li>
                            <li>
                                <Check />
                                <span>
                                    <strong>
                                        Collega la stampante al tuo pc.
                                    </strong>
                                </span>
                            </li>
                            <li>
                                <Check />
                                <span>
                                    Accertati di <strong>poter stampare a colori</strong> e di avere
                                    carta sufficiente.
                                </span>
                            </li>
                            <li>
                                <Check />
                                <span>
                                    <strong>Stampa</strong> il buono.
                                </span>
                            </li>
                            <li>
                                <Check />
                                <span>
                                    Se vuoi avere tutti i dettagli{' '}
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://www.donnad.it/buoni-sconto-faq"
                                    >
                                        leggi la nostra FAQ
                                    </a>
                                    .
                                </span>
                            </li>
                            <p>
                                <strong>Attenzione:</strong> se hai richiesto un coupon ma non è ancora stato caricato, attendi ancora qualche minuto. Stiamo processando la tua richiesta! 
                                <p>I buoni stampati in bianco e nero non verranno considerati validi.</p>
                            </p>
                        </ul>
                    </div>
                </div>

                {items.length > 0 ? (
                    <div className={style.list}>
                        {items.map((item, i) => (
                            <div className={style.prize} key={i}>
                                <div className={style.details}>
                                    <PrizeCard
                                        image={
                                            item.coupon_master_id &&
                                            item.coupon_master_id.image &&
                                            item.coupon_master_id.image.data.full_url
                                        }
                                        title={item.coupon_master_id.title}
                                        text={item.coupon_master_id.description}
                                    />
                                    <div className={style.data}>
                                        <p
                                            className="is-hidden-mobile"
                                            dangerouslySetInnerHTML={{
                                                __html: `<strong>${item.coupon_master_id.title}</strong>`,
                                            }}
                                        ></p>
                                        <p
                                            className="is-hidden-mobile"
                                            dangerouslySetInnerHTML={{
                                                __html: item.coupon_master_id.description,
                                            }}
                                        ></p>

                                        {item.status === 'created' &&
                                            item.coupon_master_id.validity_ends_on && (
                                                <div className={style.validity}>
                                                    COUPON GENERABILE ENTRO:{' '}
                                                    <strong>
                                                        {new Date(
                                                            fixDateForAllBrowsers(
                                                                item.coupon_master_id
                                                                    .validity_ends_on
                                                            )
                                                        ).toLocaleString('it-IT', {
                                                            month: 'long',
                                                            day: '2-digit',
                                                            year: 'numeric',
                                                        })}
                                                    </strong>
                                                </div>
                                            )}

                                        {(item.status === 'created' ||
                                            item.status === 'generated') && (
                                            <Cta
                                            modifiers={['bg-corporate']}
                                                label={
                                                    item.status === 'created'
                                                        ? 'Genera coupon'
                                                        : 'Scarica coupon'
                                                }
                                                onClick={() => couponTrigger(item.id, item.status)}
                                            />
                                        )}
                                        {item.status === 'downloaded' && (
                                            <div className={style.couponid}>
                                                <strong>Coupon Scaricato</strong> ID: #{item.uuid}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className={style.nores}>
                        {isLoyaltyActive(state) && (
                            <React.Fragment>
                                <p>Hey, sembra che tu non abbia riscattato ancora nessun coupon.</p>
                                <Cta label="Richiedi Premi" url="/premi" />
                            </React.Fragment>
                        )}

                        {!isLoyaltyActive(state) && state.loyalty_status === true && (
                            <LoyaltyCard logged={!!state.user} />
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Coupons;
